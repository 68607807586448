import * as styles from './PodcastPlayer.module.css'

import React from 'react'
import { compact } from 'lodash'

import { AudioPlayer } from './AudioPlayer'

interface Props {
  episode: {
    title: string
    audioPath: string | null
    timestamps:
      | {
          time: string | null
          label: string | null
        }[]
      | null
  }
}

export const PodcastPlayer: React.FC<Props> = (props) => {
  const { episode } = props

  return (
    <div className={styles.container}>
      {episode.audioPath != null && episode.audioPath.length > 0 ? (
        <AudioPlayer
          episode={{
            title: episode.title,
            audioPath: episode.audioPath,
            timestamps: compact(episode.timestamps),
          }}
        />
      ) : (
        <div className={styles.unavailable}>Episode coming soon</div>
      )}
    </div>
  )
}
