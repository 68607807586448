import * as styles from './TrailerButton.module.css'

import { usePlayerState } from './PlayerContext'

import React from 'react'

export const TrailerButton: React.FC = () => {
  const { playing, setRequestedAction } = usePlayerState()

  return (
    <button
      className={styles.trailerButton}
      onClick={() => {
        setRequestedAction(playing ? 'pause' : 'play')
      }}
    >
      <span className={styles.trailerButtonIcon}>{playing ? '⏸' : '⏵'}</span>{' '}
      {playing ? 'Pause' : 'Play Trailer'}
    </button>
  )
}
