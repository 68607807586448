import * as styles from './TagList.module.scss'

import React from 'react'
import { compact, kebabCase } from 'lodash'
import { Link } from 'gatsby'
import classNames from 'classnames'

interface Props {
  label?: string
  tags: readonly (string | null)[]
  activeTag?: string
}

export const TagList: React.FC<Props> = (props) => {
  const { label, tags, activeTag } = props

  return (
    <div className={styles.container}>
      {label != null && <div className={styles.label}>{label}</div>}
      {compact(tags).map((tag) => {
        return kebabCase(tag) === activeTag ? (
          <div key={kebabCase(tag)} className={styles.tag}>
            {tag}
          </div>
        ) : (
          <Link
            to={`/podcast/tag/${kebabCase(tag)}`}
            key={kebabCase(tag)}
            className={classNames(styles.tag, styles.tagLink)}
          >
            {tag}
          </Link>
        )
      })}
    </div>
  )
}
