// title: Lucky Paper Radio — A Podcast about Magic: the Gathering
// frameCardName: Arcane Denial
// frameCardSet: A25

import * as styles from './index.module.scss'

import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { uniq } from 'lodash'

import Layout from 'components/layout/Layout'
import PageMetaData from 'src/components/layout/PageMetaData'

import { trailerAudioPath } from 'src/components/podcast/podcastConfig'

import { PlayerContextProvider } from 'src/components/podcast/PlayerContext'
import { EpisodeList } from 'src/components/podcast/EpisodeList'
import { PodcastHeading } from 'src/components/podcast/PodcastHeading'
import { PodcastPlayer } from 'src/components/podcast/PodcastPlayer'
import { TagList } from 'src/components/podcast/TagList'
import { TrailerButton } from 'src/components/podcast/TrailerButton'

interface Props extends PageProps<Queries.PodcastPageQuery> {
  pageContext: {
    url: string
  }
}

const PodcastPage: React.FC<Props> = (props) => {
  const { podcasts } = props.data.allMdx

  const allTags = uniq(
    podcasts.flatMap((episode) => episode.frontmatter?.tags ?? []),
  )

  return (
    <Layout
      {...props.pageContext}
      accentColor="#044AB7"
      includeNewsletterSignup
    >
      <PlayerContextProvider>
        <section>
          <PodcastHeading>
            <h1 className={styles.title}>Lucky Paper Radio</h1>
            <p className={styles.excerpt}>
              Lucky Paper Radio is a podcast about Magic: the Gathering hosted
              by lifelong friends{' '}
              <a href="https://bsky.app/profile/andy.luckypaper.co">
                Andy Mangold
              </a>{' '}
              and{' '}
              <a href="https://bsky.app/profile/anthony.luckypaper.co">
                Anthony Mattox
              </a>
              . Focused primarily on sandbox formats like Cube, which are free
              of banlists, rotation, and net decking, the show is about the best
              parts of Magic and how to get the most out of the game.
            </p>

            <TrailerButton />
          </PodcastHeading>

          <div className={styles.tags}>
            <TagList label="Browse By Tag:" tags={allTags} />
          </div>

          <EpisodeList podcasts={podcasts} />

          <PodcastPlayer
            episode={{
              title: 'Trailer',
              audioPath: trailerAudioPath,
              timestamps: null,
            }}
          />
        </section>
      </PlayerContextProvider>
    </Layout>
  )
}

export const query = graphql`
  query PodcastPage {
    allMdx(
      filter: { fields: { type: { eq: "podcast-notes" } } }
      sort: { frontmatter: { date: DESC } }
    ) {
      podcasts: nodes {
        id
        frontmatter {
          title
          date(formatString: "MMMM Do, YYYY")
          excerpt
          episodeSlug
          episodeNumber
          episodeOrder
          tags
        }
      }
    }
  }
`

export const Head: React.FC<Props> = (props) => {
  return (
    <PageMetaData
      url={props.pageContext.url}
      metaData={{
        title: 'Lucky Paper Radio — A Podcast about Magic: the Gathering',
        description:
          'A podcast about Magic: the Gathering and related topics, brought to you by Andy Mangold and Anthony Mattox.',
        ogImageURL: 'https://luckypaper.co/og-images/lucky-paper-radio.jpg',
        rssFeed: {
          title: 'Lucky Paper Radio',
          url: 'https://anchor.fm/s/29f49e0c/podcast/rss',
        },
      }}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'PodcastSeries',
        name: 'Lucky Paper Radio',
        description:
          'Lucky Paper Radio is a podcast about Magic: the Gathering hosted by lifelong friends Andy Mangold and Anthony Mattox. Focused primarily on sandbox formats like Cube, which are free of banlists, rotation, and net decking, the show is about the best parts of Magic and how to get the most out of the game.',
        url: props.pageContext.url,
        webFeed: 'https://anchor.fm/s/29f49e0c/podcast/rss',
        genre: 'Games',
        startDate: '2020-07-05',
        creator: [
          {
            '@type': 'Person',
            name: 'Andy Mangold',
          },
          {
            '@type': 'Person',
            name: 'Anthony Mattox',
          },
        ],
      }}
    />
  )
}

export default PodcastPage
