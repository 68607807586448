import * as styles from './PodcastHeading.module.scss'

import React from 'react'

import podcastArt from './lucky-paper-radio-artwork.jpg'

import { usePlayerState } from './PlayerContext'

import { VendorLinks } from './VendorLinks'

interface Props {
  children: React.ReactNode
}

export const PodcastHeading: React.FC<Props> = (props) => {
  const { children } = props

  const { playing, setRequestedAction } = usePlayerState()

  return (
    <div className={styles.container}>
      <div className={styles.masthead}>
        <button
          className={styles.mastheadButton}
          onClick={() => {
            setRequestedAction(playing ? 'pause' : 'play')
          }}
        >
          <img
            src={podcastArt}
            className={styles.mastheadImage}
            alt="Lucky Paper Radio Podcast Artwork"
          />
        </button>
        <div className={styles.mastheadInfo}>{children}</div>
      </div>

      <VendorLinks />
    </div>
  )
}
