// extracted by mini-css-extract-plugin
export var bonus = "EpisodeList-module--bonus--49176";
export var container = "EpisodeList-module--container--eb945";
export var date = "EpisodeList-module--date--54c9d";
export var details = "EpisodeList-module--details--8ae60";
export var episode = "EpisodeList-module--episode--d2f69";
export var episodeNumber = "EpisodeList-module--episodeNumber--b48ec";
export var excerpt = "EpisodeList-module--excerpt--c7f23";
export var main = "EpisodeList-module--main--b682e";
export var playButton = "EpisodeList-module--playButton--115ed";
export var tags = "EpisodeList-module--tags--57c13";
export var title = "EpisodeList-module--title--571ef";