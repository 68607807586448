import * as styles from './EpisodeList.module.scss'

import React, { useMemo } from 'react'
import { sortBy } from 'lodash'
import { Link } from 'gatsby'

import { TagList } from './TagList'

interface Props {
  readonly podcasts: ReadonlyArray<{
    readonly id: string
    readonly frontmatter: {
      readonly title: string
      readonly date: string | null
      readonly excerpt: string | null
      readonly episodeSlug: string | null
      readonly episodeNumber: number | null
      readonly episodeOrder: number | null
      readonly tags: ReadonlyArray<string | null> | null
    } | null
  }>
  activeTag?: string
}

export const EpisodeList: React.FC<Props> = (props) => {
  const { podcasts, activeTag } = props

  const sortedEpisodes = useMemo(() => {
    // Gatsby's sorting seems to not work completely consistently
    return sortBy(
      podcasts,
      (episode) => episode.frontmatter?.episodeOrder,
    ).reverse()
  }, [podcasts])

  return (
    <div className={styles.container}>
      {sortedEpisodes.map((podcast) => {
        if (podcast.frontmatter == null) {
          return null
        }

        return (
          <div className={styles.episode} key={podcast.id}>
            <Link
              to={`/podcast/${podcast.frontmatter.episodeSlug}/`}
              className={styles.main}
            >
              {podcast.frontmatter.episodeNumber ? (
                <div className={styles.episodeNumber}>
                  Episode {podcast.frontmatter.episodeNumber}
                </div>
              ) : (
                <div className={styles.bonus}>Bonus Episode</div>
              )}
              <div className={styles.title}>{podcast.frontmatter.title}</div>
              <div className={styles.date}>{podcast.frontmatter.date}</div>
            </Link>

            <div className={styles.details}>
              <div className={styles.excerpt}>
                {podcast.frontmatter.excerpt}
              </div>

              {podcast.frontmatter.tags != null &&
                podcast.frontmatter.tags?.length > 0 && (
                  <div className={styles.tags}>
                    <TagList
                      tags={podcast.frontmatter.tags}
                      activeTag={activeTag}
                    />
                  </div>
                )}
            </div>

            <div className={styles.playButton}>▶&#xFE0E;</div>
          </div>
        )
      })}
    </div>
  )
}
