import * as styles from './VendorLinks.module.scss'

import React from 'react'

import { podcastVendors } from './podcastVendors'

export const VendorLinks: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>Listen &amp; Subscribe:</div>
      {podcastVendors.map((vendor, index) => (
        <a className={styles.link} href={vendor.link} key={index}>
          <img
            className={styles.icon}
            src={`/podcast-vendor-icons/${vendor.icon}`}
            alt={`${vendor.label} Icon`}
          />
          {vendor.label}
        </a>
      ))}
    </div>
  )
}
