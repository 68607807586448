// extracted by mini-css-extract-plugin
export var colorFade = "AudioPlayer-module--colorFade--44900";
export var container = "AudioPlayer-module--container--03dce";
export var currentTime = "AudioPlayer-module--currentTime--3b830";
export var defaultPlayer = "AudioPlayer-module--defaultPlayer--82c03";
export var duration = "AudioPlayer-module--duration--c226b";
export var playPauseButton = "AudioPlayer-module--playPauseButton--3c000";
export var playerRow = "AudioPlayer-module--playerRow--46e1a";
export var playhead = "AudioPlayer-module--playhead--e14c6";
export var playing = "AudioPlayer-module--playing--a0423";
export var timeCodes = "AudioPlayer-module--timeCodes--f1c09";
export var timestamp = "AudioPlayer-module--timestamp--8390f";
export var track = "AudioPlayer-module--track--a9664";
export var trackCursor = "AudioPlayer-module--trackCursor--da0b0";